<template>
  <div v-loading="loading" style="height: 100%;">
    <el-form label-width="80px" :rules="rules" ref="form" :model="form" label-position="left" :style="height">
      <el-form-item label="公司名称" prop="companyName">
        <el-input :readonly="isDetail" v-model="form.companyName" size="mini" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="公司分类" prop="tenantId">
        <el-select :disabled="isDetail" v-model="form.tenantId" clearable size="small" style="width:100%;" placeholder="请选择公司分类">
          <el-option v-for="item in roleTypes" :key="item.name" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!-- 当用户查看公司时，自己的公司是看不到上级公司的 -->
      <el-form-item label="上级公司" prop="upperBizId">
        <biz-edit :is-admin="isAdmin" :props="['upperTenantId', 'upperBizId', 'rootBizId']" :is-add="isAdd" :company-disabled="true"
        :business-id="businessId" ref="bizEdit" :is-detail="isDetail" :data-obj="form"
          @select-company="onSelectCompany" />
      </el-form-item>
      <el-form-item label="储能容量" prop="volumetric">
        <el-input :readonly="isDetail" v-model="form.volumetric" size="mini" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input :readonly="isDetail" v-model="form.address" size="mini" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input :readonly="isDetail" v-model="form.contact" size="mini" placeholder="请输入联系人"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="tel">
        <el-input :readonly="isDetail" v-model="form.tel" size="mini" maxlength="11" placeholder="请输入电话"></el-input>
      </el-form-item>
    </el-form>
    <div v-if="!isDetail">
      <foot-btns @cancel="onCancel" @submit="onSubmit" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import bizApi from "@/api/business";
import siteApi from "@/api/site";

import BizEdit from "@/components/business/edit";

export default {
  computed: {
  ...mapGetters(['user'])
  },
  components: {
    BizEdit
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    company: undefined
  },
  data () {
    return {
      loading: false,
      rowId: "",
      isAdmin: false,
      upperCompany: null,
      height: "",
      roleTypes: [].concat($statics.ROLE_GROUP_LIST),
      businessId: "",
      configs: {
        text: "",
        regxs: [ $statics.BUSINESS_CUSTOM_CONFIG_REGX, $statics.VT_CONFIG_REGX, $statics.ASSEMBLE_DATA_CONFIG_REGX ],
        values: []
      },
      form: {
        companyName: "",
        tenantId: "",
        businessId: "",
        upperTenantId: "",
        upperBizId: "",
        address: "",
        contact: "",
        version: "",
        tel: "",
        type: "",
        subsidiaryId: "",
        volumetric: "",
        // 创建公司时，默认给出所需要的权限
        configs: $statics.DEFAULT_AUTHORITY_CONFIGS
      },
      customConfig: {},
      sites: [],
      rules: {
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        tenantId: [
          { required: true, message: '请选择一个分组', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^1\d{10}$/.test(value)) {
                callback("手机号格式错误");
                return;
              }
              callback();
            }
          }
        ]
      }
    }
  },
  watch: {
    company: function() {
      if (this.isAdd && this.company !== null) {
        this.getDatas();
      }
    },
    isDetail: function () {
      this.calcHeight();
    }
  },
  created () {
    this.isAdmin = this.user.tenantId === 0;
    console.log("user-group-detail-edit");
    if (!this.isAdmin) {
      this.roleTypes.shift();
    }
  },
  async mounted () {
    this.calcHeight();
    await this.getDatas();
  },
  methods: {
    calcHeight () {
      this.height = this.isDetail ? "height: calc(100% - 40px);" : "height: calc(100% - 82px);"
    },
    async getDatas () {
      try {
        this.loading = true;
        await this.getBizDatas();
        await this.getUpperBizDatas();
        await this.getSiteDatas();
      } catch (err) {
        console.log(err);
        this.$message.error("读取失败");
      } finally {
        this.loading = false;
      }
    },
    async getBizDatas () {
      const company = this.isAdd ? Object.assign({}, this.form) : await bizApi.detail(this.$route.params.bizId);
        
      if (!this.isAdmin) {
        if (this.isAdd) {
          company.upperTenantId = this.user.tenantId;
          company.upperBizId = this.user.businessId;
        } else {
          if (company.subsidiaryId) {
            const subsidiaryId = company.subsidiaryId.split(",");
            if (subsidiaryId.length > 3) {
              const upperBizId = subsidiaryId[subsidiaryId.length - 3];
              if (upperBizId) {
                company.upperBizId = upperBizId;
              } else {
                company.upperBizId = this.user.businessId;
              }
              company.rootBizId = subsidiaryId[1];
            }
          }
        }
      }

      this.businessId = company.id;
      this.form = Object.assign({}, company);
      this.backForm = Object.assign({}, this.form);

      const { configs } = company;

      if ($statics.BUSINESS_CUSTOM_CONFIG_REGX.test(configs)) {
        let customConfig = configs.match($statics.BUSINESS_CUSTOM_CONFIG_REGX)[1];
        customConfig = JSON.parse(customConfig);
        this.customConfig = customConfig;
        if (customConfig.volumetric) {
          this.form.volumetric = customConfig.volumetric;
        }
      }

      this.form.type = (this.user.tenantId === 0) ? 1 : 2; // 只有超级管理员能创建管理公司，其余所有都是普通公司
    },
    // 获得上级公司列表
    async getUpperBizDatas () {
      // , 最高级,上级,自己,
      // 0,    -4, -3,  -2,-1
      let subsidiaryId = this.form.subsidiaryId;
      if (subsidiaryId) {
        const subsidiaryIds = subsidiaryId.split(",");
        subsidiaryId = subsidiaryIds[subsidiaryIds.length - 3];
      } else {
        subsidiaryId = this.form.businessId || this.user.businessId;
      }

      try {
        const res = await bizApi.detail(subsidiaryId);
        this.upperCompany = res;
        this.form.upperBizId = res.id;
        this.form.upperTenantId = res.tenantId;
      } catch {
        this.$message.warning("未找到当前公司的上级公司，请确认上级公司的配置没有错误！");
      }
    },
    async getSiteDatas () {
      const res = await siteApi.list({
        page:0,
        size: 99999999,
        companyId: this.form.id
      });
      this.sites = res.data;
    },
    onSelectCompany (company) {
      if (company) {
        if (company.id !== this.form.id && company.subsidiaryId.indexOf(this.form.id) > 0) {
          this.form.upperBizId = "";
          this.upperCompany = null;
          this.$message.warning(`您选择的上级公司${company.businessname}的关联关系中已包含了当前公司（${this.form.companyName}），请确认这样选择没有问题么？`);
        } else {
          this.form.upperBizId = company.id;
          this.upperCompany = company;
        }
      } else {
        this.upperCompany = null;
      }

      this.$forceUpdate();
    },
    onClose () {
      if (this.isAdd) {
        this.$router.push({
          path: "/company"
        });
      } else {
        this.$refs.form.clearValidate(["compnayName", "tenantId", "upperBizId", "address", "contact",  "tel"]);
        this.$emit("close", this.form);
      }
    },
    onCancel () {
      const title = this.isAdd ? "添加公司" : `编辑当前公司（${this.form.companyName}）`;
      $utils.cancelConfirm(this, title, () => {
        this.form = Object.assign({}, this.backForm);
        this.onClose();
      });
    },
    async onEdit (data) {
      let subsidiaryId = data.id;
      if (this.upperCompany) {
        let upperStaffCode = this.upperCompany.subsidiaryId;
        let subsidiaryIds = upperStaffCode.split(",");
        subsidiaryIds.shift();
        subsidiaryIds.pop();
        subsidiaryIds.push(subsidiaryId);
        subsidiaryId = subsidiaryIds.join();
        data.subsidiaryId = `,${subsidiaryId},`;
      } else {
        data.subsidiaryId = `,${data.id},`;
      }

      await bizApi.edit(data);
      this.form = data;
    },
    async onAdd (data) {
      if (this.upperCompany) {
        data.subsidiaryId = this.upperCompany.subsidiaryId; // staff code 必须包含自己，但此时 staff code 尚未包含自己
      }
      const id = await bizApi.add(data);
      data.id = id;
      await this.onEdit(data); // 把自己加入 staffcode
    },
    onSubmit () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }

        try {
          this.loading = true;
          const data = Object.assign({}, this.form);
          const regx = $statics.BUSINESS_CUSTOM_CONFIG_REGX;

          let configs = data.configs || "";
          if (data.volumetric) {
            this.customConfig.volumetric = data.volumetric;
          }

          let customConfig = JSON.stringify(this.customConfig);
          customConfig = `custom:${customConfig}:custom`;

          if (regx.test(configs)) {
            configs = configs.replace(regx, customConfig);
          } else {
            configs += `,${customConfig}`;
          }
          data.configs = configs;

          const method = this.isAdd ? "onAdd" : "onEdit";
          await this[method](data);

          const msg = this.isAdd ? "公司添加成功" : "公司编辑成功";
          this.$message.success(msg);

          // [20240513]
          // 因为需要在 EMS 的站点列表中，公司名字的右侧显示储能容量，所以在修改公司名称后，统一对站点的名称进行修改
          if (!this.isAdd) {
            await this.updateSites();
          }

          this.onClose();
        } catch (err) {
          console.error(err);
          const msg = this.isAdd ? "公司添加失败" : "公司编辑失败";
          this.$message.error(msg);
        } finally {
          this.loading = false;
        }
      });
    },
    async updateSites () {
      const volumetric = this.customConfig.volumetric || "";
      const arr = [];

      // 因为这里会关联更新，所以不管有没有 volumetric 都要把公司名更新掉，不然两边的数据会造成不同步
      for (const site of this.sites) {
        site.companyName = this.form.companyName + volumetric;
        arr.push(siteApi.edit(site));
      }

      await Promise.all(arr);
    }
  }
}
</script>