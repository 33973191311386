var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          style: _vm.height,
          attrs: {
            "label-width": "80px",
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司名称", prop: "companyName" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: _vm.isDetail,
                  size: "mini",
                  placeholder: "请输入公司名称",
                },
                model: {
                  value: _vm.form.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyName", $$v)
                  },
                  expression: "form.companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司分类", prop: "tenantId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.isDetail,
                    clearable: "",
                    size: "small",
                    placeholder: "请选择公司分类",
                  },
                  model: {
                    value: _vm.form.tenantId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "tenantId", $$v)
                    },
                    expression: "form.tenantId",
                  },
                },
                _vm._l(_vm.roleTypes, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上级公司", prop: "upperBizId" } },
            [
              _c("biz-edit", {
                ref: "bizEdit",
                attrs: {
                  "is-admin": _vm.isAdmin,
                  props: ["upperTenantId", "upperBizId", "rootBizId"],
                  "is-add": _vm.isAdd,
                  "company-disabled": true,
                  "business-id": _vm.businessId,
                  "is-detail": _vm.isDetail,
                  "data-obj": _vm.form,
                },
                on: { "select-company": _vm.onSelectCompany },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "储能容量", prop: "volumetric" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: _vm.isDetail,
                  size: "mini",
                  placeholder: "请输入地址",
                },
                model: {
                  value: _vm.form.volumetric,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "volumetric", $$v)
                  },
                  expression: "form.volumetric",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地址", prop: "address" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: _vm.isDetail,
                  size: "mini",
                  placeholder: "请输入地址",
                },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人", prop: "contact" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: _vm.isDetail,
                  size: "mini",
                  placeholder: "请输入联系人",
                },
                model: {
                  value: _vm.form.contact,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contact", $$v)
                  },
                  expression: "form.contact",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "电话", prop: "tel" } },
            [
              _c("el-input", {
                attrs: {
                  readonly: _vm.isDetail,
                  size: "mini",
                  maxlength: "11",
                  placeholder: "请输入电话",
                },
                model: {
                  value: _vm.form.tel,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tel", $$v)
                  },
                  expression: "form.tel",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isDetail
        ? _c(
            "div",
            [
              _c("foot-btns", {
                on: { cancel: _vm.onCancel, submit: _vm.onSubmit },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }