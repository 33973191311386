import { render, staticRenderFns } from "./company-base.vue?vue&type=template&id=bc7ad87c"
import script from "./company-base.vue?vue&type=script&lang=js"
export * from "./company-base.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace\\bms-front\\anj\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bc7ad87c')) {
      api.createRecord('bc7ad87c', component.options)
    } else {
      api.reload('bc7ad87c', component.options)
    }
    module.hot.accept("./company-base.vue?vue&type=template&id=bc7ad87c", function () {
      api.rerender('bc7ad87c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/authority/company/detail-edit/company-base.vue"
export default component.exports